export default [ 
  {
    title: 'Tareas pendientes',
    route: 'tasks',
    icon: 'HomeIcon',
  },
  {
    title: 'Tareas nuevas',
    route: 'newTasks',
    icon: 'StarIcon',
  },
  
  {
    title: 'Tareas cerradas',
    route: 'closedTasks',
    icon: 'ListIcon',
  },
    
]
