<template>
  <layout-vertical>

    <router-view />
    <div slot="breadcrumb">
      <h3 
        v-if="$route.meta.pageTitle"
        class="pr-1 mb-1">
            {{ $route.meta.pageTitle }}
      </h3>
    </div>
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <div slot="footer">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT  © {{ new Date().getFullYear() }}
          <b-link
            class="ml-25"
            href="https://evotic.es"
            target="_blank"
          >Evotic</b-link>
          <span class="d-none d-sm-inline-block">, Todos los derechos reservados</span>
        </span>
      </p>
    </div>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import {BLink} from "bootstrap-vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BLink
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
